<template>
  <div :class="contentWidth === 'boxed' ? 'container p-0' : null">
    <web-navbar />
    <main class="main-content main-content--full">
    <router-view />
    </main>
    <web-footer class="d-none d-md-block d-lg-block" />
    <bottom-navbar class="d-block d-md-none d-lg-none" />
    <!-- <bottom-navbar class="d-block d-md-none d-lg-none" /> -->
  </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import WebFooter from '@core/layouts/components/footer/Footer.vue'
import WebNavbar from '@core/layouts/components/header/Header.vue'
import BottomNavbar from '@core/layouts/components/bottom-navbar/BottomNavbar.vue'

export default {
  components: {
    WebFooter, 
    WebNavbar,
    BottomNavbar,
  },
  setup() {
    const { contentWidth } = useAppConfig()
    return { contentWidth }
  },
}
</script>
