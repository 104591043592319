<template>
<b-nav-item-dropdown class="dropdown-cart mr-25" menu-class="dropdown-menu-media" right>
    <template #button-content>
        <feather-icon :badge="cartLength" class="text-body" icon="ShoppingCartIcon" size="21" />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
        <div class="dropdown-header d-flex">
            <h4 class="notification-title mb-0 mr-auto">
                My Cart
            </h4>
            <b-badge pill variant="light-primary">
                {{ cartLength }} Items
            </b-badge>
        </div>
    </li>

    <!-- Cart Items -->
    <vue-perfect-scrollbar v-if="cartLength!==0" :settings="perfectScrollbarSettings" class="scrollable-container media-list scroll-area" tagname="li">
        <b-media class="d-flex align-items-center" v-for="item in cartData" :key="item.item_name">
            <template #aside>
                <b-img :src="item.default_img" rounded width="62px" />
            </template>
            <feather-icon icon="XIcon" class="cart-item-remove cursor-pointer" @click="removeItem(item)" />
            <div class="media-heading">
                <h6 class="cart-item-title">
                    <b-link class="text-body">
                        {{ item.item_name }}
                    </b-link>
                </h6>
                <small class="cart-item-by">In {{ item.item_type==2?'Products':'Events' }}</small>
            </div>

            <div class="cart-item-qty ml-1" v-if='item.item_type==2'>
                <b-form-spinbutton :value="item.item_qty" min="1" size="sm" @input="addQuantity($event, item)" />
            </div>

            <!-- <h5 class="cart-item-price">
          ${{ item.price }}
        </h5> -->
        </b-media>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li v-if="cartLength!==0" class="dropdown-menu-footer">
        <!-- <div class="d-flex justify-content-between mb-1">
        <h6 class="font-weight-bolder mb-0">
          Total:
        </h6>
        <h6 class="text-primary font-weight-bolder mb-0">
          ${{ totalAmount }}
        </h6>
      </div> -->
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" block :to="{ name: 'e-checkout' }" v-if="cartData.length">
            Checkout
        </b-button>
    </li>

    <p v-if="cartLength==0" class="m-0 p-1 text-center">
        Your cart is empty
    </p>
</b-nav-item-dropdown>
</template>

<script>
import {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BImg,
    BFormSpinbutton,
    BButton,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    components: {
        BNavItemDropdown,
        BBadge,
        BMedia,
        BLink,
        BImg,
        BFormSpinbutton,
        VuePerfectScrollbar,
        BButton,
        ToastificationContent
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            items: [],

            perfectScrollbarSettings: {
                maxScrollbarLength: 60,
                wheelPropagation: false,
            },
            user_id:''
        }
    },
    computed: {
        totalAmount() {
            let total = 0
            this.items.forEach(i => {
                total += i.price
            })
            return total
        },
        cartData() {
            return this.$store.state.app.cartData
        },

        cartLength() {
            return this.$store.state.app.cartLength
        }
    },
    created() {
        this.getIteminCart();
    },
    methods: {
        // fetchItems() {
        //     this.$store.dispatch('app-ecommerce/fetchCartProducts')
        //         .then(response => {
        //             this.items = response.data.products
        //         })
        // },

        async getIteminCart() {

            var myHeaders = new Headers();
            let tenant_id = JSON.parse(localStorage.getItem("domain_Data")).tenant_id;;
    let authentication = JSON.parse(localStorage.getItem("userdetails")).jwttoken;
                myHeaders.append("x-access-token", authentication);
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            const userData = localStorage.getItem("userdetails")

            if (userData == null || undefined) {
                this.user_id = null
            } else {
                this.user_id = JSON.parse(localStorage.getItem("userdetails")).user_id;
            }
        
            var urlencoded = new URLSearchParams();

            urlencoded.append("tenant_id", tenant_id);
            urlencoded.append("crm_user_id", this.user_id);
            urlencoded.append("user_type", 'public_user');
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: urlencoded,
                redirect: "follow",
            };

            const rawResponse = await fetch(

                process.env.VUE_APP_BASE_URL + "cart",

                requestOptions
            );

            const response = await rawResponse.json();
            let cartData = response.data;
            this.$store.commit("app/SET_CART_LENGTH", cartData.length);
            this.$store.commit("app/SET_CART_DATA", cartData);

        },
        async addQuantity(event, val) {

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
              let authentication = JSON.parse(localStorage.getItem("userdetails")).jwttoken;
                myHeaders.append("x-access-token", authentication);

            var urlencoded = new URLSearchParams();

            urlencoded.append("item_detail_id", val.item_detail_id);
            urlencoded.append("quantity", event);
            urlencoded.append("item_id", val.item_id);
            urlencoded.append("item_type", val.item_type);

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: urlencoded,
                redirect: "follow",
            };

            const rawResponse = await fetch( 

                process.env.VUE_APP_BASE_URL + "updateQuantity",
                requestOptions
            );

            const response = await rawResponse.json();
            if (response.data[0].status == 1) {

                this.getIteminCart();

            }
        },
        async removeItem(value) {

            var myHeaders = new Headers();
                let authentication = JSON.parse(localStorage.getItem("userdetails")).jwttoken;
            myHeaders.append("x-access-token", authentication);
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            var urlencoded = new URLSearchParams();

            urlencoded.append("item_detail_id", value.item_detail_id);
            urlencoded.append("item_id", value.item_id);
            urlencoded.append("item_type", value.item_type);

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: urlencoded,
                redirect: "follow",
            };

            const rawResponse = await fetch(

                process.env.VUE_APP_BASE_URL + "removeFromCart",
                requestOptions
            );

            const response = await rawResponse.json();
            // this.isAmount = true
            if (response.data[0].status == 1) {

                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: value.item_type == "1" ?
                            "Event removed from cart" : "Product removed from cart",

                        icon: 'CheckIcon',

                        variant: 'success'
                    },
                })
                this.getIteminCart();

            }
        },
        removeItemFromCart(productId) {
            this.$store.dispatch('app-ecommerce/removeProductFromCart', {
                    productId
                })
                .then(() => {
                    const itemIndex = this.items.findIndex(p => p.id === productId)
                    this.items.splice(itemIndex, 1)

                    // Update count in cart items state
                    this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', this.items.length)
                })
        },
    },
}
</script>

<style lang="scss" scoped>
.dropdown-cart {
    .media {
        .media-aside {
            align-items: center;
        }
    }
}
</style>
