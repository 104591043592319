<template>
  <b-modal id="modal-verifycode"
  ref="modalVerifyCode"
  hide-footer>
  <div class="login-signup px-2 py-1">
      <div class="mb-2">
        <h3>Verift Your Account</h3>
        <p>
          Please verify your account
        </p>
      </div>
      <b-form>
        <b-form-group>
          <label for="email">Email</label>
          <b-form-input
            id="email"
            type="email"
            placeholder="Email address"
            v-model="email"
            disabled
          />
        </b-form-group>
        <b-form-group>
          <b-form-input id="code" type="text" placeholder="Verify Code" v-model="code" />
        </b-form-group>
        <b-button variant="primary" block type="submit" @click="verifyCode">
          Verify Code
        </b-button>
      </b-form>
      <div class="text-center mt-2">
        <b-link v-b-modal.modal-login @click="backToLogin">
          <feather-icon icon="ChevronLeftIcon" /> Back to login
        </b-link>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { BModal, BForm, BButton, BFormGroup, BFormInput, BLink } from 'bootstrap-vue'
import { createErrorObject } from '@/utils'

export default {
  name: 'VerifyCodePopup',
  components: {
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BLink,
    BModal
  },
  data () {
    return {
      code: ''
    }
  },
  computed:  {
    email () {
      return this.$store.state.auth.email
    }
  },
  methods: {
    show () {
      this.$refs.modalVerifyCode.show()
    },
    async verifyCode (e) {
      e.preventDefault()
      if (this.code === '') {
        this.$toast(createErrorObject('Error', 'Verify Code cannot be empty'))
        return
      }
      await this.$store.dispatch('auth/verifyCode', {
        email: this.email,
        code: this.code
      })

      this.$refs.modalVerifyCode.hide()
      this.$emit('toggleLoginPopup')

    },
    backToLogin () {

    }
  }
}
</script>
