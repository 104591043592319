<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="unreadCount"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
        @click="updateNotis"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Notifications
        </h4>
        <b-badge
          pill
          variant="light-primary"
          v-if="unreadCount !== 0"
        >
          {{ unreadCount }} New
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      v-once
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Account Notification -->
      <noti-list />
      
    </vue-perfect-scrollbar>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, BFormCheckbox,
  BSpinner
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import { onCreateNotificationByToUserId } from '@/graphql/subscriptions'

import NotiList from './NotiList'

export default {
  name: 'NotificationDropdown',
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
    BSpinner,
    NotiList
  },
  directives: {
    Ripple,
  },
  data () {
    return {
      subscription: '',
      loading: true,
      // notis: []
    }
  },
  computed: {
    notis () {
      return this.$store.state.noti.notis
    },
    unreadCount () {
      return this.notis.filter(noti => !noti.isSeen).length
    }
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }
    return {
      perfectScrollbarSettings,
    }
  },
  created () {
    this.$store.dispatch('noti/fetchNotis')
    // subscribe for new notification
    try {
      this.subscription = API.graphql({
        query: onCreateNotificationByToUserId,
        variables: {
          toUserId: this.$store.state.auth.user.id
        }
      }).subscribe({
        next: ({ value }) => {
          const profileImage = value.data.onCreateNotificationByToUserId.fromUserRef.profileImage
          if (profileImage) {
            Storage.get(profileImage).then(url => {
              value.data.onCreateNotificationByToUserId.fromUserRef.profileImageUrl = url
              this.$store.commit('noti/ADD_NOTI', value.data.onCreateNotificationByToUserId)
              // this.notis.unshift(value.data.onCreateNotificationByToUserId)
            })
          }
        }
      })
    } catch (err) {
      console.log(err)
    }
  },
  methods: {
    updateNotis () {
      this.$store.dispatch('noti/updateNotis')
    },
  },
  beforeDestroy () {
    // this.subscription.unsubscribe()
  }
}
</script>

<style>

</style>
