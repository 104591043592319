<template>
  <div>
    <b-link v-for="(noti, index) in notis" :key="index" @click="goToSinglePost(noti)">
      <b-media>
        <template #aside>
          <b-avatar
            size="32"
            :src="noti.fromUserRef.profileImageUrl"
            variant="light-info"
          />
        </template>
        <p class="media-heading">
          <span
            :class="!noti.isSeen ? 'font-weight-bold' : 'font-weight-normal'"
          >
            {{ noti.content }}
          </span>
        </p>
        <small class="notification-text">{{
          noti.createdAt | elapsedTime
        }}</small>
      </b-media>
    </b-link>
  </div>
</template>

<script>
import { BLink, BMedia } from 'bootstrap-vue'
export default {
  name: "NotiList",
  components: {
    BLink, 
    BMedia
  },
  computed: {
    notis() {
      return this.$store.state.noti.notis;
    },
  },
  methods: {
    goToSinglePost (noti) {
      if (noti.postId) {
        this.$router.push(`/post/${noti.postId}`)
      }
    }
  }
};
</script>