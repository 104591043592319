<template>
<b-modal id="modal-forgotpassword" cancel-variant="outline-secondary" title="" hide-footer ref="modalForgotPassword">
    <div class="login-signup px-2 py-1">
        <div class="mb-2">
            <h3>Forgot Password?</h3>
            <p>
                Enter your email and we'll send you instructions to reset your
                password
            </p>
        </div>
        <b-form>
            <b-form-group>
                <label for="email">Email</label>
                <b-form-input id="email" type="email" placeholder="Email address" v-model="email" />
            </b-form-group>
            <b-button variant="primary" block @click='passwordRenewal()'>
                Send reset link
            </b-button>
        </b-form>
        <div class="text-center mt-2">
            <b-link @click='backToLogin()'>
                <feather-icon icon="ChevronLeftIcon" /> Back to login
            </b-link>
        </div>
    </div>
</b-modal>
</template>

<script>
import {
    BModal,
    BButton,
    BLink,
    BForm,
    BFormGroup,
    BFormInput
} from 'bootstrap-vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
    name: "ForgotPasswordPopup",
    data() {
        return {
            email: ''
        }
    },
    components: {
        BModal,
        BForm,
        BFormGroup,
        BFormInput,
        BButton,
        BLink,
        ToastificationContent
    },
    methods: {
        backToLogin(){
           this.$bvModal.hide("modal-forgotpassword")
        },
        async passwordRenewal() {
             if(this.email==''||this.email==null){
              this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 
                            "Please enter email",
                            icon: 'AlertCircleIcon',
                            
                            variant:'danger'
                        },
                    })
                return false;
          } else{

            const url = window.location.href
            const hostname = new URL(url);

            var domain_name = hostname.hostname.replace('.cloudweb.app', '')
            var myHeaders = new Headers();
             
            

            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            var urlencoded = new URLSearchParams();
             let tenant_id =JSON.parse(localStorage.getItem("domain_Data")).tenant_id;
            urlencoded.append("user_type", 'public_user');
            urlencoded.append("email", this.email);
            urlencoded.append("domain_name", domain_name);
            urlencoded.append("tenant_id", tenant_id);

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: urlencoded,
                redirect: "follow",
            };

            const rawResponse = await fetch(
                 process.env.VUE_APP_BASE_URL + "users/forgotPassword",
                requestOptions
            );

            const response = await rawResponse.json();
            if(response.status==true){
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Email send.Please check your mail',

                        icon: 'CheckIcon',

                        variant: 'success'
                    },
                })
            } else if(response.data[0].status == 0){
                   this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: response.data[0].message,
                            icon: "AlertCircleIcon",
                            variant: "danger",
                        },
                    });
                    return false;
                }
          }

        }
    }
};
</script>
