<template>
<b-modal id="modal-signup" cancel-variant="outline-secondary" title="" hide-footer ref="modalSignup">
    <div class="login-signup px-2 py-1">
        <div class="mb-2">
            <h3>Create new account</h3>
            <p>Fill the form below to create a new account.</p>
        </div>
        <b-form>
            <b-row>
                <b-col md="6">
                    <b-form-group>
                        <label for="">First Name:</label>
                        <b-form-input id="mc-first-name" placeholder="First Name" v-model='first_name' />
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group md="6">
                        <label for="">Last Name:</label>
                        <b-form-input id="mc-last-name" placeholder="Last Name" v-model='last_name' />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-form-group>
                <label for="email">Email:</label>
                <b-form-input id="email" type="email" placeholder="Email address" v-model="email" />
            </b-form-group>
            <b-form-group>
                <label for="password">Password</label>
                <b-form-input type="password" placeholder="Password" v-model="password" />
            </b-form-group>
            <b-form-group>
                <label for="password">Confirm Password</label>
                <b-form-input type="password" placeholder="Confirm password" v-model="confirmPassword" />
            </b-form-group>
            <b-form-group v-if='privacy_url !== null '>
                <b-form-checkbox id="register-privacy-policy" disabled v-model="status" name="checkbox-1" >
                    I agree to
                    <a :href='privacy_url' target='_blank'>privacy policy & terms</a>
                </b-form-checkbox>
            </b-form-group>

            <b-button variant="primary" block @click="signUp()"> Sign Up </b-button>
        </b-form>
        <div class="text-center mt-2">
            <span>Already have an account? </span>
            <b-link @click='backToLogin()'>
                <span>Login</span>
            </b-link>
        </div>
   
    </div>
</b-modal>
</template>

<script>
import {
    BModal,
    BLink,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BButton
} from 'bootstrap-vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
    name: "SignupPopup",
    data() {
        return {
            status: true,
            email: '',
            password: '',
            confirmPassword: '',
            tenant: '',
            first_name: '',
            last_name: '',
          
        }
    },
    computed: {
        privacy_url() {
            return this.$store.state.auth.privacy_url
        }
    },
    components: {
        BModal,
        BForm,
        BFormGroup,
        BFormInput,
        BFormCheckbox,
        BLink,
        BButton,
        ToastificationContent
    },
    
    methods: {
        backToLogin() {
            this.$bvModal.hide("modal-signup")
        },
      

        async signUp() {

            var mailFormat = /^([^@\s]+)@([^@\s]+)\.([^@\s]+)$/;
            if (this.first_name == '' || this.first_name == null) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Please enter first name",
                        icon: 'AlertCircleIcon',

                        variant: 'danger'
                    },
                })
                return false;
            } else if (this.last_name == '' || this.last_name == null) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Please enter last name",
                        icon: 'AlertCircleIcon',

                        variant: 'danger'
                    },
                })
                return false;
            } else if (this.email == '' || this.email == null) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Please enter email",
                        icon: 'AlertCircleIcon',

                        variant: 'danger'
                    },
                })
                return false;
            } else if (!this.email.match(mailFormat)) {
                this.$bvToast.toast('Please enter valid email', {
                    title: `Invalid Email address`,
                    variant: 'danger',
                    solid: true,
                });
                return false;
            } else if (this.password == '' || this.password == null) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Please enter password",
                        icon: 'AlertCircleIcon',

                        variant: 'danger'
                    },
                })
                return false;
            } else if (this.confirmPassword == '' || this.confirmPassword == null) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Please confirm password",
                        icon: 'AlertCircleIcon',

                        variant: 'danger'
                    },
                })
                return false;
            } else if (this.password !== this.confirmPassword) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Please enter correct password",
                        icon: 'AlertCircleIcon',

                        variant: 'danger'
                    },
                })
                return false;
            } else if (this.status == false) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Please agree to policy and terms",
                        icon: 'AlertCircleIcon',

                        variant: 'danger'
                    },
                })
                return false;
            } else {
                
                var myHeaders = new Headers();

                let tenant_id =JSON.parse(localStorage.getItem("domain_Data")).tenant_id;;
                // const api_key = JSON.parse(localStorage.getItem("domain_Data")).api_key;

                myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
                // myHeaders.append('x-api-key', api_key);
                var urlencoded = new URLSearchParams();

                urlencoded.append("tenant_id", tenant_id);
                urlencoded.append("first_name", this.first_name);
                urlencoded.append("last_name", this.last_name);
                urlencoded.append("email", this.email);
                urlencoded.append("password", this.password);

                var requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: urlencoded,
                    redirect: "follow",
                };

                const rawResponse = await fetch(

                    process.env.VUE_APP_BASE_URL + "users/publicUserSignup",
                    requestOptions
                );

                const response = await rawResponse.json();
                if (response.status == 1) {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Great news, sign up successful",
                            icon: "CheckIcon",
                            variant: "success",
                        },
                    });
                    setTimeout(() => this.$bvModal.hide("modal-signup"), 2000);

                    setTimeout(() => this.$bvModal.show("modal-login"), 2000);
                } else if (response.data[0].status == 0) {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: response.data[0].message,
                            icon: "AlertCircleIcon",
                            variant: "danger",
                        },
                    });
                    return false;
                }

            }
        }

    }
};
</script>
