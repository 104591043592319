/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateLikeByPostId = /* GraphQL */ `
  subscription OnCreateLikeByPostId($postId: ID!) {
    onCreateLikeByPostId(postId: $postId) {
      id
      postId
      userId
      createdAt
      updatedAt
    }
  }
`;
export const onCreateLikeByCommentId = /* GraphQL */ `
  subscription OnCreateLikeByCommentId($commentId: ID!) {
    onCreateLikeByCommentId(commentId: $commentId) {
      id
      commentId
      userId
      createdAt
      updatedAt
    }
  }
`;
export const onCreateLikeBySubCommentId = /* GraphQL */ `
  subscription OnCreateLikeBySubCommentId($subCommentId: ID!) {
    onCreateLikeBySubCommentId(subCommentId: $subCommentId) {
      id
      subCommentId
      userId
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCommentByPostId = /* GraphQL */ `
  subscription OnCreateCommentByPostId($postId: ID!) {
    onCreateCommentByPostId(postId: $postId) {
      id
      postId
      userId
      userRef {
        id
        email
        tenantId
        profileImage
        firstName
        lastName
        about
        headline
        industry
        jobPosition
        company
        location
        createdAt
        updatedAt
      }
      content
      images
      likesCount
      likes {
        items {
          id
          commentId
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      subCommentsCount
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSubCommentByCommentId = /* GraphQL */ `
  subscription OnCreateSubCommentByCommentId($commentId: ID!) {
    onCreateSubCommentByCommentId(commentId: $commentId) {
      id
      commentId
      commentRef {
        id
        postId
        userId
        userRef {
          id
          email
          tenantId
          profileImage
          firstName
          lastName
          about
          headline
          industry
          jobPosition
          company
          location
          createdAt
          updatedAt
        }
        content
        images
        likesCount
        likes {
          nextToken
        }
        subCommentsCount
        createdAt
        updatedAt
      }
      userId
      userRef {
        id
        email
        tenantId
        profileImage
        firstName
        lastName
        about
        headline
        industry
        jobPosition
        company
        location
        createdAt
        updatedAt
      }
      content
      images
      createdAt
      likesCount
      likes {
        items {
          id
          subCommentId
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const onCreateNotificationByToUserId = /* GraphQL */ `
  subscription OnCreateNotificationByToUserId($toUserId: ID!) {
    onCreateNotificationByToUserId(toUserId: $toUserId) {
      id
      fromUserId
      fromUserRef {
        id
        email
        tenantId
        profileImage
        firstName
        lastName
        about
        headline
        industry
        jobPosition
        company
        location
        createdAt
        updatedAt
      }
      toUserId
      toUserRef {
        id
        email
        tenantId
        profileImage
        firstName
        lastName
        about
        headline
        industry
        jobPosition
        company
        location
        createdAt
        updatedAt
      }
      postId
      content
      isSeen
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser {
    onCreateUser {
      id
      email
      tenantId
      profileImage
      firstName
      lastName
      about
      headline
      industry
      jobPosition
      company
      location
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser {
    onUpdateUser {
      id
      email
      tenantId
      profileImage
      firstName
      lastName
      about
      headline
      industry
      jobPosition
      company
      location
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser {
    onDeleteUser {
      id
      email
      tenantId
      profileImage
      firstName
      lastName
      about
      headline
      industry
      jobPosition
      company
      location
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePersonalInfo = /* GraphQL */ `
  subscription OnCreatePersonalInfo {
    onCreatePersonalInfo {
      id
      userId
      birthday
      mobile
      gender
      address_1
      address_2
      postCode
      city
      state
      country
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePersonalInfo = /* GraphQL */ `
  subscription OnUpdatePersonalInfo {
    onUpdatePersonalInfo {
      id
      userId
      birthday
      mobile
      gender
      address_1
      address_2
      postCode
      city
      state
      country
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePersonalInfo = /* GraphQL */ `
  subscription OnDeletePersonalInfo {
    onDeletePersonalInfo {
      id
      userId
      birthday
      mobile
      gender
      address_1
      address_2
      postCode
      city
      state
      country
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSocialInfo = /* GraphQL */ `
  subscription OnCreateSocialInfo {
    onCreateSocialInfo {
      id
      userId
      twitter
      facebook
      instagram
      linkedin
      youtube
      website
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSocialInfo = /* GraphQL */ `
  subscription OnUpdateSocialInfo {
    onUpdateSocialInfo {
      id
      userId
      twitter
      facebook
      instagram
      linkedin
      youtube
      website
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSocialInfo = /* GraphQL */ `
  subscription OnDeleteSocialInfo {
    onDeleteSocialInfo {
      id
      userId
      twitter
      facebook
      instagram
      linkedin
      youtube
      website
      createdAt
      updatedAt
    }
  }
`;
export const onCreateWorkExperience = /* GraphQL */ `
  subscription OnCreateWorkExperience {
    onCreateWorkExperience {
      id
      userId
      position
      company
      description
      location
      from
      to
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateWorkExperience = /* GraphQL */ `
  subscription OnUpdateWorkExperience {
    onUpdateWorkExperience {
      id
      userId
      position
      company
      description
      location
      from
      to
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteWorkExperience = /* GraphQL */ `
  subscription OnDeleteWorkExperience {
    onDeleteWorkExperience {
      id
      userId
      position
      company
      description
      location
      from
      to
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePost = /* GraphQL */ `
  subscription OnCreatePost {
    onCreatePost {
      id
      tenantId
      userId
      userRef {
        id
        email
        tenantId
        profileImage
        firstName
        lastName
        about
        headline
        industry
        jobPosition
        company
        location
        createdAt
        updatedAt
      }
      content
      images
      videoUrl
      likesCount
      likes {
        items {
          id
          postId
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      commentsCount
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePost = /* GraphQL */ `
  subscription OnUpdatePost {
    onUpdatePost {
      id
      tenantId
      userId
      userRef {
        id
        email
        tenantId
        profileImage
        firstName
        lastName
        about
        headline
        industry
        jobPosition
        company
        location
        createdAt
        updatedAt
      }
      content
      images
      videoUrl
      likesCount
      likes {
        items {
          id
          postId
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      commentsCount
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePost = /* GraphQL */ `
  subscription OnDeletePost {
    onDeletePost {
      id
      tenantId
      userId
      userRef {
        id
        email
        tenantId
        profileImage
        firstName
        lastName
        about
        headline
        industry
        jobPosition
        company
        location
        createdAt
        updatedAt
      }
      content
      images
      videoUrl
      likesCount
      likes {
        items {
          id
          postId
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      commentsCount
      createdAt
      updatedAt
    }
  }
`;
export const onCreateComment = /* GraphQL */ `
  subscription OnCreateComment {
    onCreateComment {
      id
      postId
      userId
      userRef {
        id
        email
        tenantId
        profileImage
        firstName
        lastName
        about
        headline
        industry
        jobPosition
        company
        location
        createdAt
        updatedAt
      }
      content
      images
      likesCount
      likes {
        items {
          id
          commentId
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      subCommentsCount
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateComment = /* GraphQL */ `
  subscription OnUpdateComment {
    onUpdateComment {
      id
      postId
      userId
      userRef {
        id
        email
        tenantId
        profileImage
        firstName
        lastName
        about
        headline
        industry
        jobPosition
        company
        location
        createdAt
        updatedAt
      }
      content
      images
      likesCount
      likes {
        items {
          id
          commentId
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      subCommentsCount
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteComment = /* GraphQL */ `
  subscription OnDeleteComment {
    onDeleteComment {
      id
      postId
      userId
      userRef {
        id
        email
        tenantId
        profileImage
        firstName
        lastName
        about
        headline
        industry
        jobPosition
        company
        location
        createdAt
        updatedAt
      }
      content
      images
      likesCount
      likes {
        items {
          id
          commentId
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      subCommentsCount
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSubcomment = /* GraphQL */ `
  subscription OnCreateSubcomment {
    onCreateSubcomment {
      id
      commentId
      commentRef {
        id
        postId
        userId
        userRef {
          id
          email
          tenantId
          profileImage
          firstName
          lastName
          about
          headline
          industry
          jobPosition
          company
          location
          createdAt
          updatedAt
        }
        content
        images
        likesCount
        likes {
          nextToken
        }
        subCommentsCount
        createdAt
        updatedAt
      }
      userId
      userRef {
        id
        email
        tenantId
        profileImage
        firstName
        lastName
        about
        headline
        industry
        jobPosition
        company
        location
        createdAt
        updatedAt
      }
      content
      images
      createdAt
      likesCount
      likes {
        items {
          id
          subCommentId
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const onUpdateSubcomment = /* GraphQL */ `
  subscription OnUpdateSubcomment {
    onUpdateSubcomment {
      id
      commentId
      commentRef {
        id
        postId
        userId
        userRef {
          id
          email
          tenantId
          profileImage
          firstName
          lastName
          about
          headline
          industry
          jobPosition
          company
          location
          createdAt
          updatedAt
        }
        content
        images
        likesCount
        likes {
          nextToken
        }
        subCommentsCount
        createdAt
        updatedAt
      }
      userId
      userRef {
        id
        email
        tenantId
        profileImage
        firstName
        lastName
        about
        headline
        industry
        jobPosition
        company
        location
        createdAt
        updatedAt
      }
      content
      images
      createdAt
      likesCount
      likes {
        items {
          id
          subCommentId
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const onDeleteSubcomment = /* GraphQL */ `
  subscription OnDeleteSubcomment {
    onDeleteSubcomment {
      id
      commentId
      commentRef {
        id
        postId
        userId
        userRef {
          id
          email
          tenantId
          profileImage
          firstName
          lastName
          about
          headline
          industry
          jobPosition
          company
          location
          createdAt
          updatedAt
        }
        content
        images
        likesCount
        likes {
          nextToken
        }
        subCommentsCount
        createdAt
        updatedAt
      }
      userId
      userRef {
        id
        email
        tenantId
        profileImage
        firstName
        lastName
        about
        headline
        industry
        jobPosition
        company
        location
        createdAt
        updatedAt
      }
      content
      images
      createdAt
      likesCount
      likes {
        items {
          id
          subCommentId
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const onCreateLikePost = /* GraphQL */ `
  subscription OnCreateLikePost {
    onCreateLikePost {
      id
      postId
      userId
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateLikePost = /* GraphQL */ `
  subscription OnUpdateLikePost {
    onUpdateLikePost {
      id
      postId
      userId
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteLikePost = /* GraphQL */ `
  subscription OnDeleteLikePost {
    onDeleteLikePost {
      id
      postId
      userId
      createdAt
      updatedAt
    }
  }
`;
export const onCreateLikeComment = /* GraphQL */ `
  subscription OnCreateLikeComment {
    onCreateLikeComment {
      id
      commentId
      userId
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateLikeComment = /* GraphQL */ `
  subscription OnUpdateLikeComment {
    onUpdateLikeComment {
      id
      commentId
      userId
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteLikeComment = /* GraphQL */ `
  subscription OnDeleteLikeComment {
    onDeleteLikeComment {
      id
      commentId
      userId
      createdAt
      updatedAt
    }
  }
`;
export const onCreateLikeSubComment = /* GraphQL */ `
  subscription OnCreateLikeSubComment {
    onCreateLikeSubComment {
      id
      subCommentId
      userId
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateLikeSubComment = /* GraphQL */ `
  subscription OnUpdateLikeSubComment {
    onUpdateLikeSubComment {
      id
      subCommentId
      userId
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteLikeSubComment = /* GraphQL */ `
  subscription OnDeleteLikeSubComment {
    onDeleteLikeSubComment {
      id
      subCommentId
      userId
      createdAt
      updatedAt
    }
  }
`;
export const onCreateFollow = /* GraphQL */ `
  subscription OnCreateFollow {
    onCreateFollow {
      id
      userId
      userRef {
        id
        email
        tenantId
        profileImage
        firstName
        lastName
        about
        headline
        industry
        jobPosition
        company
        location
        createdAt
        updatedAt
      }
      followerId
      followerRef {
        id
        email
        tenantId
        profileImage
        firstName
        lastName
        about
        headline
        industry
        jobPosition
        company
        location
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateFollow = /* GraphQL */ `
  subscription OnUpdateFollow {
    onUpdateFollow {
      id
      userId
      userRef {
        id
        email
        tenantId
        profileImage
        firstName
        lastName
        about
        headline
        industry
        jobPosition
        company
        location
        createdAt
        updatedAt
      }
      followerId
      followerRef {
        id
        email
        tenantId
        profileImage
        firstName
        lastName
        about
        headline
        industry
        jobPosition
        company
        location
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteFollow = /* GraphQL */ `
  subscription OnDeleteFollow {
    onDeleteFollow {
      id
      userId
      userRef {
        id
        email
        tenantId
        profileImage
        firstName
        lastName
        about
        headline
        industry
        jobPosition
        company
        location
        createdAt
        updatedAt
      }
      followerId
      followerRef {
        id
        email
        tenantId
        profileImage
        firstName
        lastName
        about
        headline
        industry
        jobPosition
        company
        location
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateNotification = /* GraphQL */ `
  subscription OnCreateNotification {
    onCreateNotification {
      id
      fromUserId
      fromUserRef {
        id
        email
        tenantId
        profileImage
        firstName
        lastName
        about
        headline
        industry
        jobPosition
        company
        location
        createdAt
        updatedAt
      }
      toUserId
      toUserRef {
        id
        email
        tenantId
        profileImage
        firstName
        lastName
        about
        headline
        industry
        jobPosition
        company
        location
        createdAt
        updatedAt
      }
      postId
      content
      isSeen
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateNotification = /* GraphQL */ `
  subscription OnUpdateNotification {
    onUpdateNotification {
      id
      fromUserId
      fromUserRef {
        id
        email
        tenantId
        profileImage
        firstName
        lastName
        about
        headline
        industry
        jobPosition
        company
        location
        createdAt
        updatedAt
      }
      toUserId
      toUserRef {
        id
        email
        tenantId
        profileImage
        firstName
        lastName
        about
        headline
        industry
        jobPosition
        company
        location
        createdAt
        updatedAt
      }
      postId
      content
      isSeen
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteNotification = /* GraphQL */ `
  subscription OnDeleteNotification {
    onDeleteNotification {
      id
      fromUserId
      fromUserRef {
        id
        email
        tenantId
        profileImage
        firstName
        lastName
        about
        headline
        industry
        jobPosition
        company
        location
        createdAt
        updatedAt
      }
      toUserId
      toUserRef {
        id
        email
        tenantId
        profileImage
        firstName
        lastName
        about
        headline
        industry
        jobPosition
        company
        location
        createdAt
        updatedAt
      }
      postId
      content
      isSeen
      createdAt
      updatedAt
    }
  }
`;
